<!-- =========================================================================================
    File Name: Main.vue
    Description: Main layout
    ----------------------------------------------------------------------------------------
    Item Name: Vuesax Admin - VueJS Dashboard Admin Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
	<vs-row vs-type="flex" vs-justify="center" vs-align="center">
		<vs-col class="main-container">
			<div class="router-view">
				<div class="router-content" :class="{ 'mt-0': navbarType == 'hidden' }">
					<div class="content-area__content">
						<transition :name="routerTransition" mode="out-in">
							<router-view @changeRouteTitle="changeRouteTitle" />
						</transition>
					</div>
				</div>
			</div>
		</vs-col>
	</vs-row>
</template>

<script>
	import themeConfig from "@/../themeConfig.js";

	export default {
		data() {
			return {
				navbarType: themeConfig.navbarType || "static",
				navbarColor: themeConfig.navbarColor || "#fff",
				routerTransition: themeConfig.routerTransition || "none",
				isNavbarDark: false,
				routeTitle: this.$route.meta.pageTitle,
				disableCustomizer: themeConfig.disableCustomizer
			};
		},
		computed: {
			contentAreaClass() {
				return "content-area-full";
			},
			navbarClasses() {
				return {
					"navbar-hidden": this.navbarType == "hidden",
					"navbar-sticky": this.navbarType == "sticky",
					"navbar-static": this.navbarType == "static",
					"navbar-floating": this.navbarType == "floating"
				};
			}
		},
		watch: {
			$route() {
				this.routeTitle = this.$route.meta.pageTitle;
			},
			isThemeDark(val) {
				if (this.navbarColor == "#fff" && val) {
					this.updateNavbarColor("#10163a");
				} else {
					this.updateNavbarColor("#fff");
				}
			}
		},
		async beforeCreate() {
			const branding = await this.$store.dispatch("branding");

			if (branding && branding.colors) {
				const root = document.documentElement;

				for (const [key, value] of Object.entries(branding.colors)) {
					root.style.setProperty(`--${key}`, value);
				}
			}
		},
		created() {
			if (this.navbarColor == "#fff" && this.isThemeDark) {
				this.updateNavbarColor("#10163a");
			} else {
				this.updateNavbarColor(this.navbarColor);
			}
		},
		methods: {
			changeRouteTitle(title) {
				this.routeTitle = title;
			},
			updateNavbarColor(val) {
				this.navbarColor = val;
				if (val == "#fff") {
					this.isNavbarDark = false;
				} else {
					this.isNavbarDark = true;
				}
			}
		}
	};
</script>

<style>
	.main-container {
		max-width: 820px;
		width: 100%;
	}
</style>
